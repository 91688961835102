<template>
  <section class="internet">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <el-row :gutter="50">
        <el-col :span="12" :offset="0"><div class="title"><h1>{{ options.title }}</h1><p><strong>{{ options.tit }}</strong></p></div></el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <ArticleContent :data="options.content" class="internet-content" />
        </el-col>
        <el-col :span="12">
          <el-image v-if="options.url" :src="options.url"></el-image>
          <div v-if="options.rightTitle" class="title"><p>{{ options.rightTitle }}</p></div>
          <ArticleContent v-if="options.rightContent" :data="options.rightContent" class="internet-content" />
        </el-col>
      </el-row>
    </section>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
import ArticleContent from '../../components/ArticleContent.vue'
export default {
  components: {imageBanner, ArticleContent},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/互联网服务.png')
      },
      options: {title: '', content: []}
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      let option
      if ( id ) {
        switch ( id ) {
          case '1':
            option = this.$store.getters.getVision && JSON.parse(JSON.stringify(this.$store.getters.getVision))
            this.setOptions(option)
            break
          case '2':
            option = this.$store.getters.getService && JSON.parse(JSON.stringify(this.$store.getters.getService))
            this.setOptions(option)
            break
          case '3':
            option = this.$store.getters.getBusiness && JSON.parse(JSON.stringify(this.$store.getters.getBusiness))
            this.setOptions(option)
            break
          default:
            option = this.$store.getters.getVision
            this.setOptions(option)
        }
      }
    },
    setOptions (obj) {
      this.$set(this.options, 'title', obj.title)
      this.$set(this.options, 'content', obj.content)
      if (obj.tit) this.$set(this.options, 'tit', obj.tit)
      if (obj.url) this.$set(this.options, 'url', obj.url)
      if (obj.rightTitle) this.$set(this.options, 'rightTitle', obj.rightTitle)
      if (obj.rightContent) this.$set(this.options, 'rightContent', obj.rightContent)
    },
  }
}
</script>

<style scoped>
.internet {background-color: #ecf0f5; padding-bottom: 1rem;}
.internet .title {font-weight: 600; border-bottom: 1px solid #666; padding: 1rem 0;}
.internet .title h1 {font-size: 3rem; color: rgba(230, 119, 0, .5); line-height:1.6;}
.internet .internet-content {margin-top: 3rem;}
.internet .internet-content /deep/ h6 {line-height: 2;}
.internet .internet-content /deep/ .title {margin: 1rem 0; line-height: 2}
.internet .internet-content /deep/ .title span {border-bottom: 2px solid #e67700; line-height: 2; display: inline-block;}
</style>